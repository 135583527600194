<template>
  <div></div>
</template>

<script>
import ApiService from "@/common/api.service";
// import { apiResource } from "@/common/app.config";

export default {
  name: "MirorAuth",
  components: {},
  data() {
    return {
      name: this.$route.query.name,
      mobile: this.$route.query.mobile,
      mirorPlaydaApiKey: process.env.VUE_APP_MIROR_PLAYDA_API_KEY,
    };
  },
  created() {
    if (this.$route.query.token === this.mirorPlaydaApiKey) {
      if (this.name && this.mobile) {
        ApiService.mirorPost(process.env.VUE_APP_MIROR_API_URL, {
          // ApiService.simplePost(apiResource.miror.checkPhone, {
          phone: this.mobile,
        })
          .then((data) => {
            if (data.data.exists === true) {
              this.goToLobby(data.data.userDetails.email);
            } else {
              this.goToRegister();
            }
          })
          .catch(() => {
            console.log("catch");
            this.goToRegister();
          });
      } else {
        console.log("name and mobile");
        this.goToRegister();
      }
    } else {
      console.log("token");
      this.goToRegister();
    }
  },
  mounted() {},
  computed: {},
  methods: {
    goToRegister() {
      this.$router.replace({
        name: "Register",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    goToLobby(email) {
      this.$store
        .dispatch("loginMirorUser", {
          mobile: this.mobile,
          name: this.name,
          email: email,
        })
        .then(() => {
          this.goToLobbyPage();
        })
        .catch(() => {});
    },
    goToLobbyPage() {
      this.$router.replace({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
  },
};
</script>

<style scoped lang="postcss"></style>
